import { Pagination, Table } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const UsersTable = ({ users, onChangePage, page }) => {
  const columns = [
    {
      title: 'Name',
      render: (data) => <Link to={`/users/${data.student_id}`}>{data.name}</Link>
    },
    {
      title: 'Email',
      render: (data) => <Link to={`/users/${data.student_id}`}>{data.email}</Link>
    },
    {
      title: 'created',
      render: (data) => <Link to={`/users/${data.student_id}`}>{dayjs(data?.created).format("DD.MM.YYYY")}</Link>
    },
  ];

  return (
    <StyledUsersTable>
      <Table
        dataSource={users?.response?.data ?? []}
        columns={columns}
        pagination={false}
      />
      <Pagination
        current={page}
        onChange={onChangePage}
        pageSize={20}
        showSizeChanger={false}
        total={users?.response?.total ?? 1}
      />
    </StyledUsersTable>
  )
}

const StyledUsersTable = styled.div`
  .ant-pagination {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }

  a {
    color: rgba(0, 0, 0, 0.88);
    display: block;
  }
`