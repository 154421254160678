import { App, Button, Card, Input, Typography } from "antd"
import axios from "axios";
import { useState } from "react";
import styled from "styled-components"
import { baseUrl } from "../api/baseUrls";
import { handleGetToken } from "../helpers/getAccessToken";

export const Login = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const { message } = App.useApp();

  const handleSuccessLogin = (response) => {
    if (response?.access_token) {
      localStorage.setItem("token", response?.access_token);
      localStorage.setItem("lw_client", response?.user.lw_client)
      handleGetToken(response?.user, () => window.location = "/");
    }
  }

  const handleLogin = () => {
    axios.post(`${baseUrl}/auth/login`, { login, password })
      .then(resp => handleSuccessLogin(resp?.data?.response))
      .catch(error => message.open({ type: 'error', content: 'The email address or password is incorrect' }))
  }

  return (
    <StyledLogin>
      <Card title="Login" className="login-card">
        <div>
          <Typography>Email</Typography>
          <Input value={login} onChange={({ target: { value } }) => setLogin(value)} />
        </div>
        <div className="my-2">
          <Typography>Password</Typography>
          <Input value={password} onChange={({ target: { value } }) => setPassword(value)} />
        </div>
        <Button
          className="submit-btn"
          type="primary"
          disabled={login?.length === 0 || password?.length === 0}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Card>
    </StyledLogin>
  )
}

const StyledLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: rgb(240, 242, 245);
  .login-card {
    width: 90%;
    max-width: 400px;
  }
  .my-2 {
    margin: 10px 0;
  }
  .submit-btn {
    width: 100%;
  }
`