import axios from "axios"

export const getUsdExchange = async () => {
  let initExchangeValues = { "usd": 0, "eur": 0, "uah": 0 };
  const resp = await axios("https://api.exchangerate-api.com/v4/latest/USD");

  if (resp.status === 200) {
    initExchangeValues.usd = resp.data?.rates?.["USD"];
    initExchangeValues.eur = resp.data?.rates?.["EUR"]
    initExchangeValues.uah = resp.data?.rates?.["UAH"]

  }

  return initExchangeValues;
}