import axios from "axios";
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components"
import { baseUrl, getLearnWorldsBaseUrl } from "../../api/baseUrls";
import { headers, headersUser } from "../../api/instance";
import { AddCourseForm } from "../../components/AddCourseForm"
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Result } from "antd";
import { UserCoursesTable } from "./UserCoursesTable";
import { handleLogout } from "../../helpers/logout";

export const User = () => {
  const [student, setStudent] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [userCourses, setUserCourses] = useState([]);

  const handleBackToUsers = () => navigate("/users");


  const handleGetCourses = async () => {
    axios.get(`${baseUrl}/courses/get-courses`, headersUser())
      .then(resp => {
        const responseCourses = resp?.data?.courses;
        if (responseCourses) {
          let coursesOptions = [];
          Object.entries(responseCourses).forEach((c) => {
            coursesOptions.push({ label: c[1].title, value: c[1].titleId })
          })
          setCourses(coursesOptions);
        }
      })
      .catch(error => console.log(error?.message === "Network Error"))
    // console.log(resp)
  }

  const handleGetUserCourses = async () => {
    setLoading(true);
    const resp = await axios.get(`${baseUrl}/student/get-student-courses`,
      {
        headers: headersUser().headers,
        params: { student_id: id }
      });
    setLoading(false);
    setUserCourses(resp?.data?.response?.courses ?? []);
    setStudent(resp?.data?.response?.student ?? null)
  }

  useEffect(() => {
    handleGetUserCourses();
    handleGetCourses()
  }, [])

  return (
    <StyledUser className="container">
      {
        loading
          ? <LoadingOutlined className="loader" />
          : student ? <>
            <Link to="/users" className="back-link">&larr; Back to users</Link>
            <AddCourseForm
              student={student}
              onAddCourse={handleGetUserCourses}
            />
            <UserCoursesTable
              userCourses={userCourses}
              courses={courses}
              refetchCourses={handleGetUserCourses}
            />
          </>
            : <Result
              status="500"
              title="User not found"
              extra={<Button type="primary" onClick={handleBackToUsers}>Back to users</Button>}
            />
      }
    </StyledUser>
  )
}

const StyledUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  width: 90%;
  margin: 0 auto;
  .back-link {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .loader {
    font-size: 50px;
    margin-top: 100px;
  }
`