import styled from "styled-components";
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { handleLogout } from "../helpers/logout";
const { Header: HeaderComponent } = Layout;

const ROUTES = [
  { key: "", label: "Home" },
  { key: "users", label: "Users" },
  { key: "logout", label: "Logout" },
]

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleChangePage = (page) => {
    if (page === "logout") {
      handleLogout()
    } else {
      navigate(`/${page}`);
    }
  }

  return (
    <StyledHeader>
      <HeaderComponent >
        <Menu
          theme="dark"
          mode="horizontal"
          items={ROUTES}
          onSelect={({ key }) => handleChangePage(key)}
          selectedKeys={[location.pathname.split("/")[1]]}
        />
      </HeaderComponent>
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  .ant-menu {
    justify-content: flex-end;
  }
`