import { useEffect, useState } from "react"
import { App, Button, DatePicker, Input, Select, Typography } from 'antd';
import styled from "styled-components";
import { getUsdExchange } from "../api/getUsdExchange";
import axios from "axios";
import { headers, headersUser } from "../api/instance";
import { baseUrl, getLearnWorldsBaseUrl } from "../api/baseUrls";
import dayjs from "dayjs";
import { handleLogout } from "../helpers/logout";

const CURRENCIES = [
  { value: "usd", label: "USD" },
  { value: "eur", label: "EUR" },
  { value: "uah", label: "UAH" },
];

export const AddCourseForm = ({ student, onAddCourse }) => {
  const { message } = App.useApp();
  const dateFormat = "DD.MM.YYYY";
  const [email, setEmail] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("usd");
  const [exchange, setExchange] = useState({});
  const [course, setCourse] = useState("");
  const [courses, setCourses] = useState("");
  const [loading, setLoading] = useState(false);
  const [checking, setChecking] = useState(false);
  const isIndonesia = localStorage.getItem("lw_client") === "62a04a8c1133f0e8be027f22";
  const [loadingCourses, setLoadingCourses] = useState(false);

  const handleGetExchangeInfo = async () => {
    const resp = await getUsdExchange();
    setExchange(resp)
  }

  const handleGetTotal = () => {
    if (price && exchange[currency]) {
      return (Number(price) / exchange[currency]).toFixed(2);
    }
    return ""
  }

  const handleGetDays = () => {
    if (dateStart && dateEnd) {
      const start = dayjs(dateStart).valueOf();
      const end = dayjs(dateEnd).valueOf();
      const ONE_DAY = 1000 * 60 * 60 * 24;

      if (start < end) {
        return Math.ceil((end - start) / ONE_DAY);
      } else {
        return ""
      }
    } else {
      return "";
    }
  }

  const handleGetCourses = async () => {
    setLoadingCourses(true);
    axios.get(`${baseUrl}/courses/get-courses`, headersUser())
      .then(resp => {
        const responseCourses = resp?.data?.courses;
        if (responseCourses) {
          let coursesOptions = [];
          Object.entries(responseCourses).forEach((c) => {
            coursesOptions.push({ label: c[1].title, value: c[1].id })
          })
          setCourses(coursesOptions);
          setLoadingCourses(false);
        }
      })
      .catch(() => setLoadingCourses(false))
    // console.log(resp)
  }

  useEffect(() => {
    handleGetExchangeInfo();
    handleGetCourses();
  }, [])


  const handleCheckExistense = ({ onExist, onNotExist, onError }) => {
    setChecking(true);
    axios.get(`${baseUrl}/student/get-student-email`, { params: { email }, headers: headersUser().headers })
      .then(resp => {
        setChecking(false);
        const studentId = resp?.data?.response?.student_id;
        const studentName = resp?.data?.response?.name;
        studentName && setName(studentName);
        studentId ? onExist(studentId) : onNotExist(studentId)
      })
      .catch(error => {
        setChecking(false);
        onError();
        error?.response?.data?.message === "Unauthenticated." && handleLogout()
      })
  }

  const handleCheckUser = () => {
    handleCheckExistense({
      onExist: () => message.open({ type: 'success', content: 'User with this email already exist' }),
      onNotExist: () => message.open({ type: 'error', content: 'User with this email does not exist' }),
      onError: () => message.open({ type: 'error', content: 'User with this email does not exist' })
    })
  }

  const handleAddCourse = (student_id) => {
    setLoading(false);
    axios.post(`${baseUrl}/student/create-student`, null, {
      params: {
        student_id,
        email,
        name,
        date_start: dayjs(dateStart).format(dateFormat),
        date_end: dayjs(dateEnd).format(dateFormat),
        days: handleGetDays(),
        price,
        course_id: course
      },
      headers: headersUser().headers
    })
      .then(resp => {
        setLoading(false);
        message.open({ type: 'success', content: 'The course added successfully' });
        onAddCourse && onAddCourse()
      })
      .catch((error) => {
        setLoading(false);
        message.open({ type: 'error', content: 'Error' });
        // handleLogout();
      })
  }

  const handleCreateUser = async () => {
    const resp = await axios.post(`${baseUrl}/student/create-new-student`,
      { email, username: name, password: "test!@214" },
      headersUser()
    );
    const userId = resp?.data?.response?.student?.student_id;
    if (userId) {
      handleAddCourse(userId);
    } else {
      setLoading(false);
      message.open({ type: 'error', content: 'Error' })
    }
  }

  const handeSubmit = () => {
    setLoading(true);
    if (student) {
      handleAddCourse(student?.student_id)
    } else {
      handleCheckExistense({
        onExist: handleAddCourse,
        onNotExist: handleCreateUser,
        onError: () => setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (student) {
      setEmail(student?.email);
      setName(student?.name);
    }
  }, [student])

  return (
    <StyledAddCourseForm>
      <div className="input-row">
        <div className="input-group">
          <Typography>Email</Typography>
          <Input
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            disabled={!!student || loading}
          />
        </div>
        {
          !student &&
          <Button
            className="ml"
            type="primary"
            disabled={checking || email?.length === 0 || loading}
            loading={checking}
            onClick={handleCheckUser}
          >
            Check
          </Button>
        }
      </div>
      <div className="input-row">
        <div className="input-group">
          <Typography>Start</Typography>
          <DatePicker
            value={dateStart}
            onChange={value => setDateStart(value)}
            format={dateFormat}
            disabled={loading}
          />
        </div>
        <div className="input-group ml">
          <Typography>End</Typography>
          <DatePicker
            value={dateEnd}
            onChange={value => setDateEnd(value)}
            format={dateFormat}
            disabled={loading}
          />
        </div>
        <div className="input-group ml">
          <Typography>Days</Typography>
          <Input value={handleGetDays()} disabled />
        </div>
      </div>
      <div className="input-row">
        <div className="input-group">
          <Typography>Name</Typography>
          <Input
            value={name}
            onChange={({ target: { value } }) => setName(value)}
            disabled={!!student || loading}
          />
        </div>
      </div>
      <div className="input-row">
        <div className="input-group">
          <Typography>Price</Typography>
          <Input
            value={price}
            onChange={({ target: { value } }) => setPrice(value)}
            type="number"
            disabled={loading}
            min={0}
          />
        </div>
        {
          !isIndonesia &&
          <>
            <div className="input-group ml">
              <Typography>Сurrency</Typography>
              <Select
                value={currency}
                options={CURRENCIES}
                onChange={value => setCurrency(value)}
                disabled={loading}
              />
            </div>
            <div className="input-group ml">
              <Typography>Exchange</Typography>
              <Input
                value={exchange[currency] ?? ""}
                disabled
              />
            </div>
            <div className="input-group ml">
              <Typography>Total USD</Typography>
              <Input value={handleGetTotal()} disabled />
            </div>
          </>
        }
      </div>
      <div className="input-row">
        <div className="input-group">
          <Typography>Course</Typography>
          <Select
            value={course}
            options={courses}
            onChange={value => setCourse(value)}
            disabled={loading || loadingCourses}
            loading={loadingCourses}
          />
        </div>
      </div>
      <Button
        type="primary"
        className="submit-btn"
        disabled={loading || email.length === 0 || name.length === 0 || !dateStart || !dateEnd || price.length === 0 || !course}
        loading={loading}
        onClick={handeSubmit}
      >
        Add
      </Button>
    </StyledAddCourseForm >
  )
}

const StyledAddCourseForm = styled.div`
  max-width:700px;
  width: 100%;
  .input-row {
    display: flex;
    align-items: end;
  }
  .input-group, .ant-select{
    width: 100%;
  }
  .ml {
    margin-left: 5px;
  }
  .submit-btn {
    width: 100%;
    margin-top: 20px;
  }
  .ant-picker {
    width: 100%;
  }
`