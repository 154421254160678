import { App, Button, DatePicker, Table } from "antd";
import dayjs from "dayjs";
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { useState } from "react";
import { baseUrl } from "../../api/baseUrls";
import { headersUser } from "../../api/instance";
import axios from "axios";
import { handleLogout } from "../../helpers/logout";


const dateFormat = "DD.MM.YYYY";


const columns = [
  {
    title: 'Name',
    render: data => data.title
  },
  {
    title: 'Start',
    render: ({ isEdit, id, date_start, edit_date_start_value, onChangeDateStart, loading }) => {
      return (
        <>
          {
            isEdit === id
              ? <DatePicker
                value={edit_date_start_value}
                onChange={onChangeDateStart}
                format={dateFormat}
                className="datepicker"
                disabled={loading}
              />
              : dayjs(date_start).format("DD.MM.YYYY")
          }</>

      )
    }
  },
  {
    title: 'End',
    render: ({ isEdit, id, date_end, edit_date_end_value, onChangeDateEnd, loading }) => {
      return (
        <>
          {
            isEdit === id
              ? <DatePicker
                value={edit_date_end_value}
                onChange={onChangeDateEnd}
                format={dateFormat}
                className="datepicker"
                disabled={loading}
              />
              : dayjs(date_end).format("DD.MM.YYYY")
          }
        </>

      )
    }
  },
  {
    title: '',
    render: ({ isEdit, onEdit, onCancelEdit, onSave, id, date_start, date_end, loading }) => {
      return (
        <>
          {
            isEdit
              ? <>
                {
                  isEdit === id &&
                  <div className="edit-course-btns">
                    <Button
                      icon={<CheckOutlined />}
                      type="primary"
                      onClick={onSave}
                      disabled={loading}
                      loading={loading}
                    />
                    <Button
                      icon={<CloseOutlined />}
                      type="primary"
                      danger
                      onClick={onCancelEdit}
                      disabled={loading}
                    />
                  </div>
                }
              </>
              : <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => onEdit({ id, date_start, date_end })}
              />
          }
        </>
      )
    }
  },
];


export const UserCoursesTable = ({ userCourses, courses, refetchCourses }) => {
  const { message } = App.useApp();
  const [isEdit, setIsEdit] = useState(null);
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [loading, setLoading] = useState(false);

  const handleGetCourseName = (courseId) => {
    return courses.find(c => c.value === courseId)?.label ?? courseId;
  }

  const handleSelectCourse = ({ date_start, date_end, id }) => {
    setIsEdit(id);
    setDateStart(dayjs(date_start));
    setDateEnd(dayjs(date_end));
  }

  const handleCancelEdit = () => {
    setIsEdit(null);
    setDateStart("");
    setDateEnd("");
  }

  const handleUpdateCourse = async () => {
    setLoading(true);
    axios.post(`${baseUrl}/student/update-course`, null,
      {
        headers: headersUser().headers,
        params: {
          course_id: isEdit,
          date_start: dayjs(dateStart).format(dateFormat),
          date_end: dayjs(dateEnd).format(dateFormat)
        }
      })
      .then(() => {
        setLoading(false);
        handleCancelEdit();
        message.open({ type: 'success', content: 'Сourse has been updated' });
        refetchCourses();
      })
      .catch((error) => {
        setLoading(false);
        handleCancelEdit();
        message.open({ type: 'error', content: 'Сourse has not been updated' });
      })

  }

  return (
    <StyledUserCoursesTable>
      <Table
        columns={columns}
        dataSource={userCourses?.map(course => ({
          title: handleGetCourseName(course.course_id),
          isEdit,
          onEdit: handleSelectCourse,
          onCancelEdit: handleCancelEdit,
          onSave: handleUpdateCourse,
          edit_date_start_value: dateStart,
          edit_date_end_value: dateEnd,
          onChangeDateStart: (value) => setDateStart(value),
          onChangeDateEnd: (value) => setDateEnd(value),
          loading,
          ...course,
        })) ?? []
        }
        pagination={false}
      />
    </StyledUserCoursesTable>
  )
}

const StyledUserCoursesTable = styled.div`
  margin-top: 40px;
  width: 100%;
  .ant-pagination {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
  .edit-course-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .datepicker {
    width: 100px;
  }
 `