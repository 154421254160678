import './App.css';
import { Route, Routes } from "react-router";
import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { Users } from './pages/Users/Users';
import { User } from './pages/User/User';
import { Layout, App as AppComponent } from 'antd';
import { Header } from './components/Header';
import { useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from './api/baseUrls';
import { headersUser } from './api/instance';
import { handleGetToken } from './helpers/getAccessToken';
import { handleLogout } from './helpers/logout';
const { Content } = Layout;

function App() {
  const token = localStorage.getItem("token");

  const handleGetUser = async () => {
    axios.get(`${baseUrl}/auth/user`, headersUser())
      .then(resp => {
        const userData = resp?.data?.response;
        if (userData) {
          localStorage.setItem("lw_client", userData?.lw_client)
          handleGetToken(userData)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    if (token) {
      handleGetUser()
    }
  }, [])

  if (!token) {
    return <Login />
  }

  return (
    <AppComponent>
      <Layout className="layout">
        <Header />
        <Content
          style={{
            padding: '0 50px',
          }}
        >
          <Routes >
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id" element={<User />} />
          </Routes>
        </Content>
      </Layout>
    </AppComponent>
  );
}

export default App;
