import styled from "styled-components";
import { AddCourseForm } from "../components/AddCourseForm";

export const Dashboard = () => {
  return (
    <StyledDashboard>
      <AddCourseForm />
    </StyledDashboard >
  )
}

const StyledDashboard = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100%;
  padding-top:  80px;
`