import { Input } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { baseUrl } from '../../api/baseUrls';
import { headersUser } from '../../api/instance';
import { handleLogout } from '../../helpers/logout';
import { UsersTable } from './UsersTable';

const { Search } = Input;

export const Users = () => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);

  const handleGetUsers = () => {
    axios.get(`${baseUrl}/student/get-students`,
      { params: { page: page, perPage: 20, q: search }, headers: headersUser().headers }
    )
      .then(resp => setUsers(resp?.data ?? []))
      .catch(error => error?.response?.status && handleLogout())
  }

  const handleSearch = (value) => handleGetUsers()


  const handleChangePage = (selectedPage) => {
    setPage(selectedPage)
  }

  // INIT GET USERS
  useEffect(() => {
    handleGetUsers();
  }, [])

  // GET USERS ON CHANGE PAGE AND SEARCH
  useEffect(() => {
    handleGetUsers();
  }, [page, search])

  return (
    <StyledUsers className='container'>
      <Search
        placeholder="search user"
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
        onSearch={handleSearch}
        className="search-user"
      />
      <UsersTable
        users={users}
        onChangePage={handleChangePage}
        page={page}
      />
    </StyledUsers>
  )
}

const StyledUsers = styled.div`
  .search-user {
    width: 90%;
    max-width: 400px;
    margin-bottom: 20px;
  }
`